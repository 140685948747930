/* ======================================================================== */
/* 39. backgrounds */
/* ======================================================================== */
.bg-dark-1
	background-color: var(--color-dark-1) !important
.bg-dark-2
	background-color: var(--color-dark-2) !important
.bg-dark-3
	background-color: var(--color-dark-3) !important
.bg-dark-4
	background-color: var(--color-dark-4) !important

.bg-light-1
	background-color: var(--color-light-1) !important
.bg-light-2
	background-color: var(--color-light-2) !important
.bg-light-3
	background-color: var(--color-light-3) !important
.bg-light-4
	background-color: var(--color-light-4) !important

.bg-white
	background-color: #fff !important
