/* ======================================================================== */
/* 25. footerThemes */
/* ======================================================================== */
[data-arts-theme-text="light"]
	.widget_nav_menu ul.menu > li a:hover
		color: #fff
	.logo__text-title
		color: #fff
	.logo__text-tagline
		color: var(--color-gray-2)
