/* ======================================================================== */
/* 5. bootstrapLayout */
/* ======================================================================== */
.container-fluid
	padding-left: var(--gutter-horizontal)
	padding-right: var(--gutter-horizontal)

.container-fluid_paddings
	padding-top: var(--gutter-horizontal)
	padding-bottom: var(--gutter-horizontal)

@media screen and (max-width: $md)
	.container_p-md-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-md-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
		[class*="col-"]
			padding-left: 0
			padding-right: 0

	.container_py-md-0
		padding-top: 0
		padding-bottom: 0

@media screen and (max-width: $sm)
	.container_p-sm-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-sm-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
		[class*="col-"]
			padding-left: 0
			padding-right: 0

	.container_py-sm-0
		padding-top: 0
		padding-bottom: 0

@media screen and (max-width: $xs)
	.container_p-xs-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-xs-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
		[class*="col-"]
			padding-left: 0
			padding-right: 0
			
	.container_py-xs-0
		padding-top: 0
		padding-bottom: 0
